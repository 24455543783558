import React from 'react'

const ProgressBar = ({progress, title = null}) => {
  return (
    <div className="flex justify-start items-center">
        <div
            role="progressbar"
            aria-valuenow={progress ?? 0}
            aria-valuemin="0"
            aria-valuemax="100"
            className="relative flex-1 p-0.5 rounded-xl border shadow-inner bg-gray-100"
        >
            <div
                className="h-5 rounded-xl bg-blue-300 animate-pulse"
                style={{ width: `${progress ?? 0}%` }}
            ></div>
            <div className="absolute inset-0 flex justify-center items-center text-blue-500 text-sm font-semibold">
                {title ?? ''} {progress ?? 0}%
            </div>
        </div>
    </div>
  )
}

export default ProgressBar
